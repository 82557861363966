import myTypes from "../../statics/types"

const pdf = (state = {
  active : false,
  unlock: false,
  fields : []
}, action) => {
  switch (action.type) {
    case myTypes.reduxTypes.SWITCH_STATE:
      if(action.stage === 2){
        return{
          ...state,
          unlock: true,
          active: true
        }

      }else{
        return{
          ...state,
          active: false
        }
      }
    default:
      return state;
  }
};

export default pdf
