import React, {lazy, Suspense, useEffect} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import '../src/styles/all.css';
import './App.css';
import '../src/styles/bootstrap.css';
import LoadingDialog from "./components/Dialogs/LoadingDialog";
import { useMediaPredicate } from 'react-media-hook';
import store from "./redux/store";
import {setGeneral} from "./redux/actions/general";

const Main = lazy(() => import('./pages/Main'));
const Home = lazy(() => import('./pages/Home'));
const Unauth = lazy(() => import('./pages/Unauth'));
const Init = lazy(() => import('./Initialization'));
const Appointment = lazy(() => import('./Appointment'));
const OdyocaBooking = lazy(() => import('./OdyocaBooking'));
const FollowupAppointmentConfirmation = lazy(() => import('./FollowupAppointmentConfirmation'));

function App() {
  const medium = useMediaPredicate('(max-width: 1024px)');

  useEffect(() => {
    store.dispatch(setGeneral({medium}));
  }, [medium]);
  return (
    <BrowserRouter>
      <div className="App">
        <Suspense fallback={<LoadingDialog/>}>
          <Switch>
            <Route exact={true} path="/" component={Main} />
            <Route path="/home" component={Home} />
            <Route path="/unauth" component={Unauth} />
            <Route path="/init" component={Init}/>
            <Route path="/appointment" component={Appointment}/>
            <Route path="/odyoca-booking" component={OdyocaBooking}/>
            <Route path="/followup-appointment-confirmation" component={FollowupAppointmentConfirmation}/>
          </Switch>
        </Suspense>
      </div>
    </BrowserRouter>
  );
}

export default App;
