import types from "../../statics/types";

const initState = {
  appointment: {
    professionals: [],
    service: '',
    view: 'week',
  }
}

export default function pageReducer(prevState = initState, action) {
  const { type, data } = action;

  switch (type) {
    case types.reduxTypes.SET_PAGE:
      console.log('set page with data: ', data)
      return { ...prevState, ...data };
    default: return prevState;
  }
}