import {languages} from "../langs/languages";
import store from "../../redux/store";

const reportTypes = {
  fr: ['8,000 Hz', '16,000 Hz', 'Champ libre'],
  en: ['8,000 Hz', '16,000 Hz', 'Free field']
}

const evaluationModes = {
  fr: ['Standard', 'Jeu', 'Visuel'],
  en: ['Standard', 'Game', 'Visual']
}

const stimulus = {
  fr: ["Sons purs", "Sons hululés", "Sons pulsés", "Bruit de bande étroite"],
  en: ["Pure sounds", "Hooted sounds", "Pulsating sounds", "Narrow band noise"]
}

const conditions = {
  fr: ["Visuel", "Jeu", "Réponse motrices"],
  en: ["Visual", "Game", "Motor response"]
}

const collaboration = {
  fr: ["Bonne", "Autre"],
  en: ["Good", "Other"]
}

const transducers = {
  fr: ["Intra-auriculaire","Supra-auriculaire","Champ libre"],
  en: ["In-ear","On-Ear","Free field"]
}

const tympanometryOptionTypes = {
  fr: ['A', 'A[d]', 'A[s]', 'B', 'C', 'Plat', 'Nt', 'Ø étanche'],
  en: ['A', 'A[d]', 'A[s]', 'B', 'C', 'Plat', 'Nt', 'Ø seal']
}

const tympanometryTypes = {
  fr: ['226 Hz', '1,000 Hz'],
  en: ['226 Hz', '1,000 Hz']
}

const languageOptions = {
  fr: ['Français', 'Anglais', 'Espagnol', 'Autre'],
  en: ['French', 'English', 'Spanish', 'Others']
}

const voiceSources = {
  fr: ['Matériel enregistré', 'Voix nue'],
  en: ['Recorded material', 'Bare voice']
}

const listOptions = {
  fr: ['', 'Images', 'Liste enfant', 'Liste Adulte'],
  en: ['', 'Images', 'Child list', 'Adult list']
}

const eoaTypes = {
  fr: ['Par produits de distorsion', 'Transient'],
  en: ['By distortion products', 'Transient']
}

const eoaFrequencies = ['500hz', '1,000hz', '1,500hz', '2,000hz', '2,500hz', '3,000hz', '4,000hz', '5,000hz', '6,000hz', '7,000hz', '8,000hz', '9,000hz', '10,000hz'];

const emissionOptions = {
  fr: ['', 'Présent', 'Absent', 'NT'],
  en: ['', 'Present', 'Absent', 'NT']
}

const testValidationOptions = {
  fr: ['Bonne', 'Moyenne', 'Nulle', 'Repos sonore inadéquat'],
  en: ['good', 'medium', 'Nothing', 'Inadequate sound test']
}

const ansiOptions = {
  fr: ['Oui', 'Non'],
  en: ['Yes', 'No']
}

const numberOfWordsOptions = {
  fr: ["10", "25", "50", "Autre", "Aucun"],
  en: ["10", "25", "50", "Other", "None"]
}

const getReportOptions = () => {
  const { general } = store.getState();
  const { lang } = general;
  if (lang === languages.FR) {
    return {
      reportTypes: reportTypes.fr,
      evaluationModes: evaluationModes.fr,
      stimulus: stimulus.fr,
      conditions: conditions.fr,
      collaboration: collaboration.fr,
      transducers: transducers.fr,
      tympanometryTypes: tympanometryTypes.fr,
      tympanometryOptionTypes: tympanometryOptionTypes.fr,
      languageOptions: languageOptions.fr,
      voiceSources: voiceSources.fr,
      listOptions: listOptions.fr,
      eoaTypes: eoaTypes.fr,
      eoaFrequencies: eoaFrequencies,
      emissionOptions: emissionOptions.fr,
      testValidationOptions: testValidationOptions.fr,
      ansiOptions: ansiOptions.fr,
      numberOfWordsOptions: numberOfWordsOptions.fr,
    }
  }
  else if (lang === languages.EN) {
    return {
      reportTypes: reportTypes.en,
      evaluationModes: evaluationModes.en,
      stimulus: stimulus.en,
      conditions: conditions.en,
      collaboration: collaboration.en,
      transducers: transducers.en,
      tympanometryTypes: tympanometryTypes.en,
      tympanometryOptionTypes: tympanometryOptionTypes.en,
      languageOptions: languageOptions.en,
      voiceSources: voiceSources.en,
      listOptions: listOptions.en,
      eoaTypes: eoaTypes.en,
      eoaFrequencies: eoaFrequencies,
      emissionOptions: emissionOptions.en,
      testValidationOptions: testValidationOptions.en,
      ansiOptions: ansiOptions.en,
      numberOfWordsOptions: numberOfWordsOptions.en,
    }
  }
}

export default getReportOptions