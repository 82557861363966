import { combineReducers } from "redux";
import user from './user';
import general from "./general";
import alertDialog from "./alertDialog";
import pdf from "./pdf";
import notifications from './notifications';
import dataLoader from './dataLoader';
import page from "./page";

export default combineReducers({
  user,
  general,
  alertDialog,
  pdf,
  notifications,
  dataLoader,
  page,
});