export const colors = {
  primary: "#4685c5",
  darkenPrimary: "#315d8a",
  lightPrimary: "#90b6dc",
  ultraLightPrimary: "#edf3f9",
  adminGreen: "rgba(149, 230, 222, 1)",
  text : "#7D7D7D",
  blue: "#3366CC",
  lightBlue: "#c2d1f0",
  lightgray: "#C3C3C3",
  overlay: "rgba(0,0,0,0.2)",
  white: "#FFFFFF",
  red: "#fb6060",
  lightRed: "#fecfcf",
  ultraLightRed: "#ffefee",
  darkenRed: "#b04343",
  fill: "rgba(0,0,0,0.08)",
  lightFill: "rgba(0, 0, 0, 0.04)",
  darkGrey: "#737373",
  orange: "#ff6700",
  warning: "#ffeb3b",
  darkWarning: "#4c4612",
  lightWarning: "#fff9c4",
  success: "#4CAF50",
  lightSuccess: "#c9e7ca",
  info: "#2196F3",
  nl: "#21203a",
  gs: "#7d6e5b",
  email: '#ca0000',
  fax: '#314a5e',
  download: '#008001',
  user: '#009891',
  green: '#06d9c8',
  black: "#000000",
}