import types from "../../statics/types";
const initState = {
  show: false,
  type: '',
  title: '',
  body: '',
  afterConfirmed: {}
};

export default function generalReducer(preState = initState, action) {
  const { type, data } = action;
  switch (type) {
    case types.reduxTypes.SET_ALERTDIALOG:
      return { ...data };
    case types.reduxTypes.RESET_ALERTDIALOG:
      return { ...initState };
    default:
      return preState;
  }
}