import React from 'react';
import {Dialog} from "@progress/kendo-react-dialogs";
import {colors} from "../../styles/colors";
import './style.css';

const LoadingDialog = () => {
  return (
    <Dialog
      width={100}
      height={100}
      className="loading-dialog"
    >
      <span
        className="k-icon k-i-loading"
        style={{
          fontSize: "60px",
          fontWeight: "bold",
          color: colors.primary,
          backgroundColor: "transparent",
          display: "contents"
        }}
      />
    </Dialog>
  );
};

export default LoadingDialog;