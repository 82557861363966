import types from "../../statics/types";
const initState = {};

export default function userReducer(preState = initState, action) {
  const { type, data } = action;
  switch (type) {
    case types.reduxTypes.SET_USER:
      return data;
    default:
      return preState;
  }
}