export const professionalTypes = {
  // AUDIOLOGIST: 'audiologist',
  // ENT: 'ent',
  // AUDIOPROSTHETIST : 'audioprosthetist',
  AUDIOLOGIST: {
    name: 'audiologist',
    nameFr: 'Audiologiste',
    nameEn: 'Audiologist'
  },
  ENT: {
    name: 'ent',
    nameFr: 'ORL',
    nameEn: 'ENT'
  },
  AUDIOPROSTHETIST : {
    name: 'audioprosthetist',
    nameFr: 'Audioprothésiste',
    nameEn: 'Audioprosthetist'
  },
};